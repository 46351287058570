import React from 'react';
import parser from 'html-react-parser';
import youtube_logo from './youtube_logo.png';
import './EpisodeVideo.css';

const Project = ({ project }) => {
    return (
        <div className="episode-container">
            <div className="episode-detail">
                <div>
                    <img 
                        width="364" 
                        height="275"
                        title={project.title}
                        src={project.image}
                        alt={project.title}
                    />
                </div>
            </div>
            <div className="episode-detail">
                <h4>{project.title}</h4>
                <p className="duration">Video Language: {project.videolanguage}</p>
                <div className="description">
                    { parser(project.description) }
                </div>
                <p>
                    <a 
                        href={project.url}
                        target="_blank"
                        rel="noreferrer"
                    >
                        Watch on YouTube
                        <img src={youtube_logo} alt="YouTube Logo" className="youtube-logo"></img>
                    </a> 
                    <br />
                    <a 
                        href={project.demourl}
                        target="_blank"
                        rel="noreferrer"
                    >
                        View Online Demo
                    </a> 
                </p>
            </div>
        </div>
)};

export default Project;