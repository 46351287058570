// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `nav {
    border-bottom: 4px solid gray;
    text-align: center;
    position: fixed;
    top: 0;
    width: 100%;
    background: white;
    display: flex;
    align-items: center;
}
  
nav h1 {
    flex-basis: 30%;
    color: gray;
}
  
nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    flex-basis: 70%;
}
  
nav li {
    display: inline-block;
    padding-top: 16px;
    padding-bottom: 16px;
}
  
.navlink {
    text-decoration: none;
    color: black;
    text-shadow: gray 4px 2px 5px;
    font-weight: 700;
    padding: 16px 32px;
    margin: 10px;
}
  
.navlink:hover {
    background: gray;
    color: #FFEA00;
    text-shadow: black 5px 2px 5px;
}
  
.active {
    background: gray;
    color: #FFEA00;
    text-shadow: black 5px 2px 5px;
}  `, "",{"version":3,"sources":["webpack://./src/components/NavBar.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,kBAAkB;IAClB,eAAe;IACf,MAAM;IACN,WAAW;IACX,iBAAiB;IACjB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,WAAW;AACf;;AAEA;IACI,qBAAqB;IACrB,SAAS;IACT,UAAU;IACV,eAAe;AACnB;;AAEA;IACI,qBAAqB;IACrB,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,qBAAqB;IACrB,YAAY;IACZ,6BAA6B;IAC7B,gBAAgB;IAChB,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,cAAc;IACd,8BAA8B;AAClC;;AAEA;IACI,gBAAgB;IAChB,cAAc;IACd,8BAA8B;AAClC","sourcesContent":["nav {\r\n    border-bottom: 4px solid gray;\r\n    text-align: center;\r\n    position: fixed;\r\n    top: 0;\r\n    width: 100%;\r\n    background: white;\r\n    display: flex;\r\n    align-items: center;\r\n}\r\n  \r\nnav h1 {\r\n    flex-basis: 30%;\r\n    color: gray;\r\n}\r\n  \r\nnav ul {\r\n    list-style-type: none;\r\n    margin: 0;\r\n    padding: 0;\r\n    flex-basis: 70%;\r\n}\r\n  \r\nnav li {\r\n    display: inline-block;\r\n    padding-top: 16px;\r\n    padding-bottom: 16px;\r\n}\r\n  \r\n.navlink {\r\n    text-decoration: none;\r\n    color: black;\r\n    text-shadow: gray 4px 2px 5px;\r\n    font-weight: 700;\r\n    padding: 16px 32px;\r\n    margin: 10px;\r\n}\r\n  \r\n.navlink:hover {\r\n    background: gray;\r\n    color: #FFEA00;\r\n    text-shadow: black 5px 2px 5px;\r\n}\r\n  \r\n.active {\r\n    background: gray;\r\n    color: #FFEA00;\r\n    text-shadow: black 5px 2px 5px;\r\n}  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
