import React from 'react';
import Project from './Project';

const ProjectList = ({ heading, projects }) => {
    const data = Array.from(projects);
    return (
        <>
            <h1>{ heading }</h1>
            {data.map(project => {
                return <Project project={project} key={project.key} />
            })}
        </>
    )
};

export default ProjectList;