const projects = [
    {
        "title": "Create a Wishlist with JavaScript",
        "key": "v1",
        "url": "https://youtu.be/q9BKsbFjYSk",
        "demourl": "/projects/wishlist/wishlist.html",
        "image": "/projects/wishlist/images/project_th.jpg",
        "description": `<p>
            This project explains how to create a Wishlist using HTML, CSS and JavaScript.
        </p>`,
        "videolanguage": "Hindi",
        "fee": "Free"
    },    
];

export default projects;