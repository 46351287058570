import React from 'react';

const ThankYouPage = () => (
    <>
        <h1>Registration Successful</h1>
        <article>
            <h2>Thank you for joining!</h2>
            <p>You will receive further details on your registered email / mobile.</p>
        </article>
    </>
);

export default ThankYouPage;