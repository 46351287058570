// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.episode-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    background-color: white;
    border-radius: 10px;
    margin-bottom: 10px;
}
  
  
.episode-container h4 {
    display: block;
    margin-bottom: 0px;
    text-shadow: black 1px 1px 5px;  
}
 
.episode-detail {
    width: 364px;
    margin: 10px 50px;
}

.episode-detail a {
    /* display: block; */
    display: flex;
    line-height: 2em;
    background-color: lightblue;
    border: 2px solid blue;
    border-radius: 10px;
    padding: 5px;
    text-decoration: none;
}

.duration {
    margin: 0px;
    font-size: 0.9em;
}
  
.description {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 0.8em;
}`, "",{"version":3,"sources":["webpack://./src/components/EpisodeVideo.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,2BAA2B;IAC3B,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB;AACvB;;;AAGA;IACI,cAAc;IACd,kBAAkB;IAClB,8BAA8B;AAClC;;AAEA;IACI,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,oBAAoB;IACpB,aAAa;IACb,gBAAgB;IAChB,2BAA2B;IAC3B,sBAAsB;IACtB,mBAAmB;IACnB,YAAY;IACZ,qBAAqB;AACzB;;AAEA;IACI,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,4DAA4D;IAC5D,gBAAgB;AACpB","sourcesContent":[".episode-container {\r\n    display: flex;\r\n    flex-direction: row;\r\n    flex-wrap: wrap;\r\n    justify-content: flex-start;\r\n    background-color: white;\r\n    border-radius: 10px;\r\n    margin-bottom: 10px;\r\n}\r\n  \r\n  \r\n.episode-container h4 {\r\n    display: block;\r\n    margin-bottom: 0px;\r\n    text-shadow: black 1px 1px 5px;  \r\n}\r\n \r\n.episode-detail {\r\n    width: 364px;\r\n    margin: 10px 50px;\r\n}\r\n\r\n.episode-detail a {\r\n    /* display: block; */\r\n    display: flex;\r\n    line-height: 2em;\r\n    background-color: lightblue;\r\n    border: 2px solid blue;\r\n    border-radius: 10px;\r\n    padding: 5px;\r\n    text-decoration: none;\r\n}\r\n\r\n.duration {\r\n    margin: 0px;\r\n    font-size: 0.9em;\r\n}\r\n  \r\n.description {\r\n    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;\r\n    font-size: 0.8em;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
